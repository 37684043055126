import React, { useState } from "react";

import { CircularProgress, CssBaseline, Container, List, ListItem, ListItemText, Grid, TextField, Typography, Button, Box } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import useQuery from '../App.jsx'

const PlaylistForm = ({ handleSubmit, loaded }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [playlistLength, setPlaylistLength] = useState(10)

  return (
    <Box display='flex' justifyContent='space-between' marginTop={3}>
      <Box display='flex' justifyContent='space-between' width={400}>
        <TextField id="outlined-basic" label="Search" onChange={(e) => setSearchTerm(e.target.value)} variant="outlined" />
        <TextField
          id="outlined-number"
          label="Length"
          type="number"
          defaultValue={playlistLength}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => setPlaylistLength(event.target.value)}
        />
      </Box>
      {loaded ? 
        <Button variant="contained" disabled={!loaded} onClick={() => handleSubmit(searchTerm, playlistLength)}>Submit</Button>
      :
        <LoadingButton loading variant="outlined" />
      }
    </Box>
  )
}

const PlaylistDisplay = ({ playlist, playlistLoaded, playlistError }) => {
  if (!playlistLoaded) {
    return(
      <Box marginTop={5}>
        <CircularProgress />
      </Box>
    )
  }

  if (playlistError) {
    return (
      <Box marginTop={5}>
        <Typography>Error generating playlist.</Typography>
        <Typography>{`${playlistError}`}</Typography>
      </Box>
    )
  }

  return(
    <Box marginTop={5}>
      <List>
        {playlist.map((element) => (
          <ListItem disablePadding key={`${element.title}:${element.artist}`}>
            <ListItemText primary={`${element.title} by ${element.artist}`} />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

const SpotifyLogin = () => {
  const client_id = 'ad44fa402b264dfda3648151aebfa4b2';
  const redirect_uri = 'http://localhost:3000/playlist';
  const state = "ABCDEFGHIJKLMNOPQ"
  const scope = 'user-read-private user-read-email'

  const link = `https://accounts.spotify.com/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}&state=${state}&scope=${scope}`

  const handleSubmit = () => {
    window.open(link, '_blank')
  }

  return (
    <Box display='flex' flexDirection='column' marginTop={3}>
      <Button onClick={() => handleSubmit()} sx={{mt: 2}} variant="outlined">Connect with Spotify</Button>
    </Box>
  )
}

export const Playlist = () => {
  const [playlistLoaded, setPlaylistLoaded] = useState(true)
  const [playlistError, setPlaylistError] = useState(false)
  const [playlist, setPlaylist] = useState([])

  let query = useQuery();



  const handlePlaylistSubmit = (searchTerm, playlistLength) => {
    setPlaylistLoaded(false)
    fetch(`https://7fueequt7g.execute-api.us-east-1.amazonaws.com/playlist/generate?search=${searchTerm}&length=${playlistLength}`, 
    {
      method: 'POST'
    })
      .then(res => res.json())
      .then(
        (result) => {
          setPlaylistLoaded(true);
          setPlaylist(result.playlist.songs)
        },
        (error) => {
          console.log(error);
          setPlaylistLoaded(true);
          setPlaylistError(error);
        }
      )
  }

  const handleSpotifyLogin = () => {
   

   
  }

  return(
    <CssBaseline enableColorScheme>
    <Container>
      <Typography align='center' variant='h1' mt='10'>
        Make Me A Playlist
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PlaylistForm handleSubmit={handlePlaylistSubmit} loaded={playlistLoaded} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <PlaylistDisplay playlist={playlist} playlistError={playlistError} playlistLoaded={playlistLoaded} />
        </Grid>
        <Grid item xs={12} sm={4}>
          
        </Grid>
      </Grid>
    </Container>
  </CssBaseline>
  )
}