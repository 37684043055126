import * as React from 'react';
import { Grid, CircularProgress, Card, CardContent, Box, Button, Container, IconButton, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { useState, useEffect } from 'react';

const DateSelector = ({ selectedDate, setSelectedDate }) => {
  const formattedDate = new Date(selectedDate).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  function handleDateChange(direction) {
    const currentDate = new Date(selectedDate);
    const dayOffset = direction === 'back' ? 1 : -1;
    const newDate = new Date(currentDate.getTime() + dayOffset * 24 * 60 * 60 * 1000);
    setSelectedDate(newDate.toISOString().split('T')[0]);
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <IconButton color="primary" onClick={() => handleDateChange('back')}>
        <ChevronLeft />
      </IconButton>
      <Typography variant="h3">{formattedDate}</Typography>
      <IconButton color="primary" onClick={() => handleDateChange('forward')}>
        <ChevronRight />
      </IconButton>
    </Box>
  );
};

const GameList = ({games}) => {
  return(
    <Grid container spacing={2}>
      {games.map((game) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={game.gameId}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  {game.away_team} @ {game.home_team}
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                <Typography variant="body2" color="text.secondary">
                  Some additional information about the game...
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                {game.video != null ? (
                  <Button onClick={() => window.open(game.video, '_blank')}>
                    HIGHLIGHTS
                  </Button>
                ) : (
                  <Typography>
                    Check back soon
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

const LeagueList = ({league, games, isLoading}) => {
  const filteredGames = games.filter(game => game.league === league);
  console.log(filteredGames)
  if (filteredGames.length === 0) {
    return null;
  }

  const leagueEmojis = {
    'NBA': '🏀',
    'MLB': '⚾️',
    'MLS': '⚽️',
    'NHL': '🏒',
  }

  return(
    <Box mt={3}>
      <Typography variant="h4">
        {leagueEmojis[league] + ' ' + league}
      </Typography>
      {isLoading ? <CircularProgress /> : 
        <GameList games={filteredGames} />
      }
    </Box> 
  )
}

export const Highlights = () => {
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    const fetchGames = async () => {
      const response = await fetch(`https://7fueequt7g.execute-api.us-east-1.amazonaws.com/sportsgames?date=${selectedDate}`);
      const data = await response.json();
      setGames(data['games']);
      setIsLoading(false);
    };

    fetchGames();
  }, [selectedDate]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        <LeagueList league="NBA" games={games} isLoading={isLoading} />
        <LeagueList league="NHL" games={games} isLoading={isLoading} />
        <LeagueList league="MLB" games={games} isLoading={isLoading} />
        <LeagueList league="MLS" games={games} isLoading={isLoading} />
      </Container>
    </Box>
  );
}