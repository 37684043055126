import React, { useState } from "react";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import WORDS from './words';

const CATEGORIES = [
  {
    name: 'fast_food',
    label: 'Fast Food'
  },
  {
    name: 'electric_appliance',
    label: 'Electric Appliances'
  },
  {
    name: 'cartoon_hero',
    label: 'Cartoons and Heros'
  },
  {
    name: 'animals',
    label: 'Animals'
  },
  {
    name: 'sports',
    label: 'Sports and Activities'
  },
  {
    name: 'movies',
    label: 'Movies'
  },
  {
    name: 'tv_shows',
    label: 'TV Shows'
  },
  {
    name: 'locations',
    label: 'Locations'
  },
  {
    name: 'vocations',
    label: 'Vocations'
  },
  {
    name: 'general',
    label: 'General'
  },
  {
    name: 'historical_figures',
    label: 'Historical Figures'
  },
  {
    name: 'fast_food_harder',
    label: 'Fast Food (Harder)'
  },
  {
    name: 'electric_appliance_harder',
    label: 'Electric Appliances (Harder)'
  },
  {
    name: 'animals_harder',
    label: 'Animals (Harder)'
  },
  {
    name: 'sports_harder',
    label: 'Sports (Harder)'
  },
  {
    name: 'movies_harder',
    label: 'Movies (Harder)'
  }
]


const TeamManager = ({words, dialogOpen, setDialogOpen, index, setIndex, handleWin, teamName}) => {
  let [timerId, setTimerId] = useState(0);
 

  const onClose = () => {
    setDialogOpen(false);
  }

  const onShow = () => {
    clearTimeout(timerId);
    setDialogOpen(true);
    setTimerId(setTimeout(function () {
      setDialogOpen(false);
    }, 3000));
  }

  const onNext = () => {
    setIndex(index + 1);
    if (index === words.length - 1) {
      handleWin(teamName);
    } else {
      onShow();
    }
  }

  const color = teamName === 'Blue' ? 'cornflowerblue' : 'indianred'

  return(
    <Box display='flex' justifyContent='center' flexDirection='column' margin={2}>
      <Typography align='center'>
        {`Words Remaining: ${words.length === 0 ? 0 : words.length - (index + 1)}`}
      </Typography>
      <Button sx={{ width: 'auto', height:75, padding: 1, margin: 2, backgroundColor: color }} variant='contained' onClick={onShow}>
        RE-SHOW
      </Button>
      <Button sx={{ width: 'auto', height:200, padding: 1, margin: 2, backgroundColor: color }} variant='contained' onClick={onNext}>
        NEXT
      </Button>
      <Dialog onClose={onClose} open={dialogOpen}>
        <Typography align='center' margin={5}>
          {words[index]}
        </Typography>
      </Dialog>
    </Box>
  )
}

const GameManager = ({activeCategories, gameLength}) => {
  let [blueIndex, setBlueIndex] = useState(0);
  let [redIndex, setRedIndex] = useState(0);
  let [blueDialogOpen, setBlueDialogOpen] = useState(false)
  let [redDialogOpen, setRedDialogOpen] = useState(false)
  let [gameDialogOpen, setGameDialogOpen] = useState(false)
  let [gameDialogText, setGameDialogText] = useState("")
  let [gameWords, setGameWords] = useState([])
  
  

  const startGame = () => {
    let newGameWords = []
    const allWords = []
    activeCategories.forEach((category) => {
      allWords.push(...WORDS[category])
    })
    console.log(gameLength)
    for (let i = 0; i < gameLength; i++) {
      let index = Math.floor(Math.random() * allWords.length)
      newGameWords.push(allWords[index])
      allWords.splice(index, 1)
    }

    setGameWords(newGameWords);
    setBlueIndex(0);
    setRedIndex(0);
    setGameDialogText(newGameWords[0])
    setGameDialogOpen(true);
    setTimeout(function () {
      setGameDialogOpen(false);
    }, 3000);

    console.log(`Started game with word list: ${newGameWords}`)
  }

  const handleWin = (teamName) => {
    setGameDialogText(`${teamName} Wins!`)
    setGameDialogOpen(true)
    setTimeout(function () {
      setGameDialogOpen(false);
    }, 3000);
  }

  return(
    <React.Fragment>
      <Box display='flex' justifyContent='space-between' margin={'auto'}>
        <TeamManager 
          words={gameWords} 
          dialogOpen={blueDialogOpen} 
          setDialogOpen={setBlueDialogOpen} 
          index={blueIndex} 
          setIndex={setBlueIndex} 
          handleWin={handleWin} 
          teamName={'Red'}
        />
        <TeamManager 
          words={gameWords} 
          dialogOpen={redDialogOpen} 
          setDialogOpen={setRedDialogOpen} 
          index={redIndex} 
          setIndex={setRedIndex} 
          handleWin={handleWin}
          teamName={'Blue'} 
        />
      </Box>
      <Box display='flex' justifyContent='center'>
        <Button color='primary' size='large' variant='contained' onClick={startGame}>Start Game</Button>
      </Box>
      <Dialog onClose={() => setGameDialogOpen(false)} open={gameDialogOpen}>
        <Typography align='center' margin={5}>
          {gameDialogText}
        </Typography>
      </Dialog>
    </React.Fragment>
  )

}

const SettingsModalContent = ({gameLength, setGameLength, activeCategories, setActiveCategories}) => {
  const handleCategoryChange = (event) => {
    console.log(event.target.name)
    console.log(event.target.checked)

    if (event.target.checked) {
      setActiveCategories(activeCategories.concat(event.target.name))
    } else {
      setActiveCategories(activeCategories.filter((e) => e !== event.target.name))
    }
  }

  return(
    <>
      <DialogTitle>Settings</DialogTitle>
      <Box marginLeft={3} marginRight={3} marginBottom={3}>
        <Typography variant='subtitle2'>Categories</Typography>
        <FormGroup>
          {CATEGORIES.map((category) => {
            return <FormControlLabel 
              control={<Checkbox defaultChecked={activeCategories.find((e) => e === category.name) !== undefined} />} 
              label={category.label} 
              name={category.name} 
              key={category.name} 
              onChange={handleCategoryChange}
            />
          })}
        </FormGroup>
        <Box marginTop={2}>
          <TextField
            id="outlined-number"
            label="Game Length"
            type="number"
            defaultValue={gameLength}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => setGameLength(event.target.value)}
          />
        </Box>
      </Box>
    </>
  )
}

export const Ffeach = () => {
  let [gameLength, setGameLength] = useState(10)
  let [settingsOpen, setSettingsOpen] = useState(false)
  let [activeCategories, setActiveCategories] = useState(['fast_food', 'electric_appliance', 'cartoon_hero'])

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline enableColorScheme>
        <Container> 
          <Box display='flex' flexDirection='column'>
            <Typography align='center' variant='h1' mt='10'>
              FFEACH
            </Typography>
            <Box display='flex' justifyContent='center'>
              <IconButton onClick={() => setSettingsOpen(true)}>
                <SettingsIcon />
              </IconButton>
              <Dialog onClose={() =>  setSettingsOpen(false)} open={settingsOpen}>
                <SettingsModalContent 
                  gameLength={gameLength} 
                  setGameLength={setGameLength} 
                  activeCategories={activeCategories} 
                  setActiveCategories={setActiveCategories} 
                />
              </Dialog>
            </Box>
            <GameManager activeCategories={activeCategories} gameLength={gameLength} />
          </Box>
        </Container>
      </CssBaseline>
    </ThemeProvider>
  );
}
