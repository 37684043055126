export const Home = () => {
  return(
    <div>
      <h1>Hello</h1>
      <p>This page is a work in progress, for now, you can visit:</p>
       <a href="ffeach">ffeach</a>
       <br />
       <a href="playlist">playlist</a>
       <br />
       <a href="highlights">highlights</a>
    </div>
  )
}